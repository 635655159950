import { LinkType } from "types/header";

export const mainNavLinks: LinkType[] = [
  {
    link: '/products/',
    title: 'All Products'
  },
  {
    title: 'Watering',
    link: '/products/collections/watering/',
    disableDropdown: true,
    items: [
      {
        link: '/products/rachio-3/',
        name: 'Smart Sprinkler Controller',
      },
      {
        link: '/products/smart-hose-timer/',
        name: 'Smart Hose Timer',
      },
      {
        link: '/products/collections/watering/',
        name: 'Shop all Watering products'
      },
    ]
  },
  {
    title: 'Gardening',
    link: '/products/collections/gardening/',
    disableDropdown: true,
    items: [
      {
        link: '/products/smart-hose-timer/',
        name: 'Smart Hose Timer',
      },
      {
        link: '/products/collections/dig-drip-kits/',
        name: 'Dig Drip Kits',
      },
      {
        link: '/products/collections/gardening/',
        name: 'Shop all Gardening products'
      },
    ]
  },
  // {
  //   title: 'Fertilizer',
  //   link: '/products/collections/fertilizer/',
  // },
  {
    title: 'Smart Yard',
    disableDropdown: true,
    link: '/products/collections/smart-yard/',
    items: [
      {
        link: '/products/rachio-3/',
        name: 'Smart Sprinkler Controller',
      },
      {
        link: '/products/smart-hose-timer/',
        name: 'Smart Hose Timer',
      },
      {
        link: '/products/weatherflow-tempest/',
        name: 'Tempest Weather System',
      },
      {
        link: '/products/collections/smart-yard/',
        name: 'Shop all Smart Yard products'
      },
    ]
  },
  {
    title: 'Bundle & Save',
    link: '/products/smart-yard-bundle-builder/',
  },
  {
    link: '/faq/',
    title: 'Resources',
    items: [
      {
        link: '/blog/',
        name: 'Blog',
      },
      {
        name: 'FAQ',
        link: '/faq/',
      },
      {
        name: 'Innovation',
        link: '/patents/',
      },
      {
        name: 'Rebates',
        link: '/rebates/',
      },
      {
        name: 'Reviews',
        link: '/reviews/',
      },
      {
        name: 'Customer Stories',
        link: '/customer-stories/',
      },
      {
        link: '/rachio-app/',
        name: 'Explore the App',
        isMobileOnlyLink: true
      },
      {
        name: 'What is Weather Intelligence™',
        link: '/weather-intelligence/',
      },
    ]
  },
]

export const utilityNavLinks: LinkType[] = [
  {
    isExternalLink: true,
    link: 'https://support.rachio.com',
    title: 'Support',
  },
  {
    isExternalLink: true,
    link: 'https://pro.rachio.com',
    title: 'Pro',
  },
  {
    isExternalLink: false,
    link: '/utility/',
    title: 'Utility',
  },
  {
    link: '/rachio-app/',
    title: 'Explore the App',
  },
]

export const footerLinks = [
  {
    title: 'Apps',
    items: [
      {
        isExternalLink: true,
        name: 'Android',
        link: 'https://play.google.com/store/apps/details?id=com.rachio.iro',
      },
      {
        isExternalLink: true,
        name: 'iOS',
        link: 'https://apps.apple.com/us/app/rachio/id864325098',
      },
      {
        isExternalLink: true,
        name: 'Web Login',
        link: 'https://app.rach.io/',
      },
    ],
  },
  {
    title: 'Company',
    items: [
      {
        name: 'About Us',
        link: '/about-us/',
      },
      {
        name: 'Our Mission',
        link: '/mission/',
      },
      {
        name: 'Newsroom',
        link: '/newsroom/',
        id: 'newsroom',
      },
      {
        name: 'Careers',
        link: '/careers/',
      },
      {
        name: 'Reviews',
        link: '/reviews/',
        id: 'reviews',
      },
      {
        name: 'Customer Stories',
        link: '/customer-stories/',
      },
      {
        name: 'Affiliate Program',
        link: 'https://signup.linkshare.com/publishers/registration/landing?mid=45858',
        id: 'affiliate-program',
        isExternalLink: true,
      },
      {
        name: 'Utility Programs',
        link: '/utility/',
      },
      {
        isExternalLink: true,
        name: 'Rachio Pro', 
        link: 'https://pro.rachio.com',
      },
      {
        name: 'B2B Sales', 
        link: '/b2b-contact-us/',
      },
    ],
  },
  {
    title: 'Support',
    items: [
      {
        name: 'FAQs',
        link: '/faq/',
      },
      {
        isExternalLink: true,
        name: 'Shipping',
        link: 'https://support.rachio.com/S1KbWR13',
      },
      {
        isExternalLink: true,
        name: 'Help Center',
        link: 'https://support.rachio.com',
      },
      {
        isExternalLink: true,
        name: 'Community',
        link: 'https://community.rachio.com',
      },
      {
        name: 'Rebates',
        link: '/rebates/',
      },
      {
        isExternalLink: true,
        name: 'Service Status',
        link: 'https://rachio.statuspage.io',
      },
      {
        isExternalLink: true,
        name: 'Security concerns',
        link: 'mailto:security@rachio.com',
      },
    ],
  },
  {
    title: 'Legal',
    items: [
      {
        name: 'Privacy Settings',
        link: '#',
        onClick: () => {
          const event = new CustomEvent('consentUpdated');
          window.dispatchEvent(event);
        }
      },
      {
        name: 'Terms of Use',
        link: '/terms-and-conditions/',
      },
      {
        name: 'Privacy Policy',
        link: '/privacy-policy/',
      },
      {
        name: 'California Privacy Policy',
        link: '/privacy-policy-california/',
      },
      {
        name: 'Do Not Sell My Personal Information',
        link: '/do-not-sell-my-personal-information/',
      },
      {
        name: 'Warranty',
        link: '/warranty/',
      }
    ],
  },
]
